<template>
  <div id="init" v-loading="loading" element-loading-text="页面加载中..."  element-loading-background="rgba(173 ,216, 230, 0.8)"></div>
</template>
<script setup>
  import pf from "@/assets/js/pub_fun.js";
  import wx from "weixin-js-sdk";
  import pcj from '@/assets/js/aes_encrypt.js';
  import {
		getCurrentInstance,
		ref,
		reactive,
		onMounted
	} from 'vue'
  // 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

  const loading = ref(true)
  onMounted(() => {
    goTo()
  })
  const closeWin = (times = 2000) => {
    setTimeout(() => {
      window.opener = null;
      window.open("", "_self");
      window.location.href = "about:blank";
      window.close();

      document.addEventListener(
        "WeixinJSBridgeReady",
        function() {
          window.WeixinJSBridge.call("closeWindow");
        },
        false
      );
      window.WeixinJSBridge.call("closeWindow");
    }, times);
  }
  const goTo = () => {
    if (pf.isWeiXin()) {
      if (pf.isMobile()) {
        ctx.$message({
          message: "请通过PC端企业微信内置浏览器打开",
          type: 'error'
        })
        closeWin();
      } else {
        const params = {
          auth_path: ctx.$route.fullPath,
          req_type: 1,
        };
        ctx.$request_nl_.post(ctx.$api_.state.Frame.Login.get_qy_jssdk.url, params)
          .then(res => {
            if (res.Code === 200) {
              wx.config({
                beta: true,
                debug: false,
                appId: res.Data.appId,
                timestamp: res.Data.timestamp,
                nonceStr: res.Data.nonceStr,
                signature: res.Data.signature,
                jsApiList: ["openDefaultBrowser"],
              });
              wx.ready(function() {
                wx.invoke(
                  "openDefaultBrowser", {
                    url: res.Data.auth_url,
                  },
                  function(res) {
                    if (res.err_msg == "openDefaultBrowser:ok") {
                      ctx.$message({
                        message: "打开默认浏览器成功，即将进行授权登录",
                        type: 'success'
                      })
                    } else {
                      ctx.$message({
                        message: "打开默认浏览器失败，请退出后重试",
                        type: 'error'
                      })
                    }
                   closeWin();
                  }
                );
              });
            } else {
              let msg = res.Message ? res.Message : "一键登录失败，请重试！！";
              ctx.$message({
                message: msg,
                type: 'error'
              })
              closeWin();
            }
          })
      }
    } else {
      const code = ctx.$route.query.code ? ctx.$route.query.code : pf.getQueryString("code");
      const state = ctx.$route.query.state ? ctx.$route.query.state : pf.getQueryString("state");
      if (!code) {
        ctx.$message({
          message: "登录错误！",
          type: 'error'
        })
        ctx.$router.push("/sign_account");
      } else {
        window.location.href =
          `${window.location.origin}${window.location.pathname}#/Init?code=${code}&state=${state}`;
      }
    }
  }
</script>

<style lang="less" scoped>
  #init {
    width: 100vw;
    min-height: calc(100vh - 60px);
    display: flex;

    .right-wp {
      flex: 1;
      width: calc(100vw - 150px);
      background-color: var(--theme-bg-color);
    }

    .right-wp.expand {
      width: calc(100vw - 250px);
    }
  }
</style>
